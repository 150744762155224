<script>
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
  import StarterKit from '@tiptap/starter-kit';
  import { Editor, EditorContent } from '@tiptap/vue-3';
  import BulletList from "@tiptap/extension-bullet-list";
  import OrderedList from "@tiptap/extension-ordered-list";
  import HardBreak from "@tiptap/extension-hard-break";
  import Heading from "@tiptap/extension-heading";
  import { convert } from 'html-to-text';
  import { DOMSerializer } from 'prosemirror-model';

  export default {
    components: {
      EditorContent,
    },

    props: {
      modelValue: {
        type: String,
        default: '',
      },
    },

    emits: ['update:modelValue'],

    setup(props, { emit }) {
      const editor = ref(null);

      watch(
        () => props.modelValue,
        (value) => {
          if (!editor.value) return;

          // HTML
          const isSame = editor.value.getHTML() === value;

          if (isSame) {
            return;
          }

          editor.value.commands.setContent(value, false);
        }
      );

      onMounted(() => {
        editor.value = new Editor({
          extensions: [
            StarterKit.configure({
              bulletList: false,
              orderedList: false,
              heading: false,
            }),
            Heading.configure({ levels: [1, 2, 3] }),
            BulletList,
            OrderedList,
            HardBreak.configure({ keepMarks: true }),
          ],
          content: props.modelValue,
          onUpdate: () => {
            // HTML
            emit('update:modelValue', editor.value.getHTML());
          },
        });

        const editorElement = editor.value.view.dom;

        editorElement.addEventListener('copy', (event) => {
          const { from, to } = editor.value.state.selection;

          // Skip if no content is selected
          if (from === to) {
            return;
          }

          // Extract the selected content as a ProseMirror Slice
          const slice = editor.value.state.doc.slice(from, to);

          // Serialize the slice to a DOM fragment
          const schema = editor.value.schema; // TipTap's schema
          const serializer = DOMSerializer.fromSchema(schema); // ProseMirror's DOMSerializer
          const div = document.createElement('div');

          slice.content.forEach((node) => {
            div.appendChild(serializer.serializeNode(node));
          });

          // Get the HTML string
          const selectedHTML = div.innerHTML;

          // Prevent default browser copy behavior
          event.preventDefault();

          let newFormat = selectedHTML
            .replace(/<h1>/g, '<p>').replace(/<\/h1>/g, '</p>')
            .replace(/<h2>/g, '<p>').replace(/<\/h2>/g, '</p>')
            .replace(/<h3>/g, '<p>').replace(/<\/h3>/g, '</p>');

          event.clipboardData.setData('text/html', newFormat);
        });
      });

      onBeforeUnmount(() => {
        if (editor.value) {
          editor.value.destroy();
        }
      });

      const setHeading = (level) => {
      if (editor.value) {
        editor.value.chain().focus().toggleHeading({ level }).run();
      }
    };

    const setParagraph = () => {
      if (editor.value) {
        editor.value.chain().focus().setParagraph().run();
      }
    };

    const toggleBold = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleBold().run();
      }
    };

    const toggleItalic = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleItalic().run();
      }
    };

    const toggleBulletList = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleBulletList().run();
      }
    };

    const toggleOrderedList = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleOrderedList().run();
      }
    };

    const copyContent = () => {
      const slice = editor.value.state.doc;

      // Serialize the slice to a DOM fragment
      const schema = editor.value.schema; // TipTap's schema
      const serializer = DOMSerializer.fromSchema(schema); // ProseMirror's DOMSerializer
      const div = document.createElement('div');

      slice.content.forEach((node) => {
        div.appendChild(serializer.serializeNode(node));
      });

      // Get the HTML string
      const selectedHTML = div.innerHTML;

      let newFormat = selectedHTML
        .replace(/<h1>/g, '<p>').replace(/<\/h1>/g, '</p>')
        .replace(/<h2>/g, '<p>').replace(/<\/h2>/g, '</p>')
        .replace(/<h3>/g, '<p>').replace(/<\/h3>/g, '</p>');

      var html = newFormat;

      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([html], { type: 'text/html' }),
        'text/plain': new Blob([html], { type: 'text/plain' })
      });

      navigator.clipboard.write([clipboardItem]);
    };

    return {
      editor,
      setHeading,
      setParagraph,
      toggleBold,
      toggleItalic,
      toggleBulletList,
      toggleOrderedList,
      copyContent
    };
  },
  };
  </script>

  <style lang="scss">
  /* Basic editor styles */
  .tiptap {
    padding: 1rem;

    :first-child {
      margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
      padding: 0 1rem;
      margin: 1.25rem 1rem 1.25rem 0.4rem;

      li p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
      }
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
      margin-top: 1rem;
      text-wrap: pretty;
      font-weight: 800;
    }

    h1 {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 1.2rem;
    }

    h3 {
      font-size: 1.1rem;
    }

    /* Code and preformatted text styles */
    code {
      background-color: var(--purple-light);
      border-radius: 0.4rem;
      color: var(--black);
      font-size: 0.85rem;
      padding: 0.25em 0.3em;
    }

    pre {
      background: var(--black);
      border-radius: 0.5rem;
      color: var(--white);
      font-family: 'JetBrainsMono', monospace;
      margin: 1.5rem 0;
      padding: 0.75rem 1rem;

      code {
        background: none;
        color: inherit;
        font-size: 0.8rem;
        padding: 0;
      }
    }

    blockquote {
      border-left: 3px solid var(--gray-3);
      margin: 1.5rem 0;
      padding-left: 1rem;
    }

    hr {
      border: none;
      border-top: 1px solid var(--gray-2);
      margin: 2rem 0;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }
  }

  @media (max-width: 767px) {
    .tiptap {
      h1 {
        font-size: 1.2rem;
      }

      h2 {
        font-size: 1.1rem;
      }

      h3 {
        font-size: 1rem;
      }

      p, li {
        font-size: 0.9rem;
      }

      code {
        font-size: 0.75rem;
      }

      pre code {
        font-size: 0.7rem;
      }
    }
  }
</style>

<template>
  <div>
      <v-toolbar
      dense
      floating
      >
          <v-btn icon @click="setHeading(1)" v-tooltip="'Header 1'">
              <v-icon>mdi-format-header-1</v-icon>
          </v-btn>
          <v-btn icon @click="setHeading(2)" v-tooltip="'Header 2'">
              <v-icon>mdi-format-header-2</v-icon>
          </v-btn>
          <v-btn icon @click="setHeading(3)" v-tooltip="'Header 3'">
              <v-icon>mdi-format-header-3</v-icon>
          </v-btn>
          <v-btn icon @click="setParagraph" v-tooltip="'Paragraph'">
              <v-icon>mdi-format-paragraph</v-icon>
          </v-btn>
          <v-btn icon @click="toggleBold" v-tooltip="'Bold'">
              <v-icon>mdi-format-bold</v-icon>
          </v-btn>
          <v-btn icon @click="toggleItalic" v-tooltip="'Italic'">
              <v-icon>mdi-format-italic</v-icon>
          </v-btn>
          <v-btn icon @click="toggleBulletList" v-tooltip="'Bullet List'">
              <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
          <v-btn icon @click="toggleOrderedList" v-tooltip="'Numbered List'">
              <v-icon>mdi-format-list-numbered</v-icon>
          </v-btn>
          <v-btn icon @click="copyContent" v-tooltip="'Copy'">
              <v-icon>mdi-content-copy</v-icon>
          </v-btn>
      </v-toolbar>
      <div class="w-full border-2 rounded">
          <editor-content :editor="editor" />
      </div>
  </div>
</template>