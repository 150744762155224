<script setup>
    import { ref } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import { useClipboard } from '@vueuse/core';

    import TipTap from '../TipTap.vue';
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    import { convert } from 'html-to-text';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        comments: Object,
        payload: Object,
    });

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };

    const commentsTextArea = ref('');

    const copyComments = () => {
        // Get the HTML content from the comments text area (or any other source)
        let selectedHTML = commentsTextArea.value;

        let newFormat = selectedHTML
            .replace(/<h1>/g, '<p>').replace(/<\/h1>/g, '</p>')
            .replace(/<h2>/g, '<p>').replace(/<\/h2>/g, '</p>')
            .replace(/<h3>/g, '<p>').replace(/<\/h3>/g, '</p>');

        var html = newFormat;

        const clipboardItem = new ClipboardItem({
            'text/html': new Blob([html], { type: 'text/html' }),
            'text/plain': new Blob([html], { type: 'text/plain' })
        });

        navigator.clipboard.write([clipboardItem]);
    };

    const capitalizeFirstLetter = (val) => {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    const convertObjectToHTML = (data) => {
        // Create unordered list for strength responses
        const strengthList = data.strengths.map(response => `<li>${capitalizeFirstLetter(response)}</li>`).join('');
        const strengthHTML = `<h1>Areas Of Strength</h1><ul>${strengthList}</ul>`;

        // Create unordered list for growth responses
        const growthList = data.growths.map(response => `<li>${capitalizeFirstLetter(response)}</li>`).join('');
        const growthHTML = `<h1>Areas Of Growth</h1><ul>${growthList}</ul>`;

        // Create paragraph for additional narrative response
        const narrativeHTML = `<p>${capitalizeFirstLetter(data.additional)}</p>`;

        // Combine all parts into a single HTML string
        return `${data.strengths.length ? strengthHTML : ''}${data.growths.length ? growthHTML : '' }${data.additional ? narrativeHTML : ''}`;
    }

    commentsTextArea.value = convertObjectToHTML(props.comments);

    const loading = ref(false);

    const regenerate = async () => {
        loading.value = true;

        axios.post('/api/v1/report-cards/generate-comment', props.payload)
            .then(response => {
                commentsTextArea.value = convertObjectToHTML({
                    strengths: response.data?.strength_responses,
                    growths: response.data?.growth_responses,
                    additional: response.data?.additional_narrative_response,
                });

                toast.open({
                    message: 'Re-generation successful.',
                    type: 'success',
                });
            })
            .catch(_ => {
                toast.open({
                    message: 'There was an error regenerating. Please try again.',
                    type: 'error',
                });
            }).finally(_ => {
                loading.value = false;
            });
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        title="Report Card Comments"
        @close="onClose">
        <div class="flex flex-col gap-4 items-center w-full">
            <div class="w-full lg:min-h-screen-1/2">
                <TipTap v-model="commentsTextArea" />
            </div>

            <div class="flex items-center justify-center gap-4">
                <div>
                    <v-btn color="success" @click="copyComments()">Copy <v-icon>mdi-content-copy</v-icon></v-btn>
                </div>
                <div>
                    <v-btn color="primary" @click="regenerate" :disabled="loading">Re-generate <v-icon v-if="!loading" icon="mdi-auto-fix" class="ml-2"></v-icon><v-progress-circular v-else indeterminate class="ml-2" model-value="20"></v-progress-circular></v-btn>
                </div>
            </div>
        </div>
    </BaseModal>
</template>
