<script setup>
import { ref, computed, watch, onMounted, onUpdated, onBeforeUnmount } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import { useDisplay } from 'vuetify';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

// Page props
const { props } = usePage();

// Pinia store
const userStore = useUserStore();
const { userIsAdmin } = storeToRefs(userStore);

// Menu config
const drawer = ref(true);
const rail = ref(true);

// Vuetify display service
const display = useDisplay();

// Menu items
const menuItems = computed(() => {
    if (userIsAdmin.value) {
        return [
            { title: 'Dashboard', icon: 'mdi-view-dashboard', href: '/dashboard' },
            { title: 'Clients', icon: 'mdi-account-group-outline', href: '/clients' },
            { title: 'Admin Users', icon: 'mdi-account-group-outline', href: '/admins' },
            { title: 'Report Cards', icon: 'mdi-pencil-outline', href: '/report-cards' },
        ];
    } else {
        return [
            { title: 'Dashboard', icon: 'mdi-view-dashboard', href: '/dashboard' },
            { title: 'Report Cards', icon: 'mdi-pencil-outline', href: '/report-cards' },
        ];
    }
});

const comingSoonMenuItems = [
    { title: 'IPP/IEP', icon: 'mdi-clock-outline', href: '#', comingSoon: true },
    { title: 'Formative Feedback', icon: 'mdi-clock-outline', href: '#', comingSoon: true },
    { title: 'Email Response', icon: 'mdi-clock-outline', href: '#', comingSoon: true },
    { title: 'PLC', icon: 'mdi-clock-outline', href: '#', comingSoon: true },
    { title: 'Academic Prompt', icon: 'mdi-clock-outline', href: '#', comingSoon: true },
    { title: 'Lesson Planning', icon: 'mdi-clock-outline', href: '#', comingSoon: true },
    { title: 'AOTW', icon: 'mdi-clock-outline', href: '#', comingSoon: true },
    { title: 'Joke of the day', icon: 'mdi-clock-outline', href: '#', comingSoon: true },
]

const bottomItems = [
    { title: 'Feedback', icon: 'mdi-form-dropdown', href: '/feedback' },
    { title: 'Settings', icon: 'mdi-cog-outline', href: '/settings' },
    { title: 'Logout', icon: 'mdi-logout', href: '/logout' },
];

// Current path
const page = usePage();
const currentPath = computed(() => page.url);

// Monitor screen size
watch(
    () => display.mdAndDown.value,
    (isMdAndDown) => {
        if (isMdAndDown) {
            drawer.value = false;
            rail.value = false;
        } else {
            drawer.value = true;
            rail.value = true;
        }
    },
    { immediate: true }
);
const showComingSoon = ref(false);

const isScrollVisible = ref(false); // Tracks if scrollbar is showing
const scrollableContainer = ref(null); // Reference to scrollable div

const checkScroll = () => {
    const container = scrollableContainer.value;
    if (container) {
        // Scrollbar is visible if scrollHeight exceeds clientHeight
        isScrollVisible.value = container.scrollHeight > container.clientHeight;
    }
};

// Mounting lifecycle
onMounted(() => {
    checkScroll(); // Check scroll visibility on mount
    window.addEventListener("resize", checkScroll); // Recheck on window resize
});

// Updating lifecycle
onUpdated(() => {
    checkScroll(); // Recheck after DOM updates
});

// Cleanup before unmount
onBeforeUnmount(() => {
    window.removeEventListener("resize", checkScroll); // Cleanup event listener
});

</script>

<template>
    <v-responsive>
        <v-app>
            <!-- App Bar -->
            <v-app-bar flat class="border-b">
                <v-app-bar-nav-icon
                    @click="drawer = !drawer"
                ></v-app-bar-nav-icon>
                <v-toolbar-title>
                    <div class="flex items-center justify-between w-full">
                        <!-- Center Logo -->
                        <div class="flex-1 flex justify-center cursor-pointer hover:opacity-75">
                            <img src="/imgs/logo.svg" class="h-12" alt="The Teacher's Site Logo" @click="$inertia.visit('/dashboard')" />
                        </div>

                        <!-- Right-Aligned Settings -->
                        <Link
                            href="/settings"
                            class="cursor-pointer pr-2 flex gap-2 items-center hover:text-primary"
                        >
                            <div class="text-sm">
                            <span class="hidden lg:block">
                                Welcome Back, {{ props.auth?.user?.first_name }}
                            </span>
                            </div>
                            <v-icon icon="mdi-account-circle-outline" />
                        </Link>
                    </div>
                </v-toolbar-title>
            </v-app-bar>

            <!-- Drawer (Sidebar) -->
            <v-navigation-drawer
                :rail-width="60"
                app
                expand-on-hover
                @mouseenter="showComingSoon = true"
                @mouseleave="showComingSoon = false"
                mobile-breakpoint="md"
                v-model="drawer"
                :rail="rail"
                :style="{
                    background:
                        'linear-gradient(157.38deg, #F6F6FB 8.53%, #E6E1EC 44.37%, #DBE8F4 78.12%)',
                }"
            >
                <!-- Wrapper with flex layout -->
                <div class="flex flex-col h-100">
                    <!-- Scrollable Area -->
                    <div class="overflow-y-auto overflow-x-hidden relative flex-grow-1" ref="scrollableContainer">
                    <div v-if="(isScrollVisible && showComingSoon) || (isScrollVisible && !drawer)" class="p-2 absolute bottom-0 right-0 animate-bounce text-gray-500"><v-icon>mdi-arrow-up-down</v-icon></div>
                    <!-- Main Menu Items -->
                    <v-list>
                        <v-list-item
                        v-for="(item, index) in menuItems"
                        :key="index"
                        :class="{ 'text-primary': currentPath.includes(item.href) }"
                        >
                        <Link :href="item.href" class="flex gap-2 py-3 hover:text-primary">
                            <v-icon>{{ item.icon }}</v-icon>
                            <v-list-item-title>
                            {{ item.title }}
                            </v-list-item-title>
                        </Link>
                        </v-list-item>
                    </v-list>

                    <!-- "Coming Soon" Label -->
                    <v-label class="ml-4 mt-4 italic font-bold" :class="{ 'opacity-0': !showComingSoon && drawer && rail }">
                        Coming Soon
                    </v-label>

                    <!-- Coming Soon Items -->
                    <v-list>
                        <v-list-item
                        v-for="(item, index) in comingSoonMenuItems"
                        :key="index"
                        :class="{ 'text-primary': currentPath.includes(item.href) }"
                        >
                        <Link href="#" class="flex gap-2 py-3 hover:text-primary">
                            <v-icon>{{ item.icon }}</v-icon>
                            <v-list-item-title>
                            {{ item.title }}
                            </v-list-item-title>
                        </Link>
                        </v-list-item>
                    </v-list>
                    </div>

                    <!-- Bottom Links (Always Visible) -->
                    <v-list class="min-h-44">
                    <v-list-item
                        v-for="(item, index) in bottomItems"
                        :key="index"
                        :class="{ 'text-primary': currentPath.includes(item.href) }"
                    >
                        <Link :href="item.href" class="flex gap-2 py-3 hover:text-primary">
                        <v-icon>{{ item.icon }}</v-icon>
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                        </Link>
                    </v-list-item>
                    </v-list>
                </div>
            </v-navigation-drawer>

            <!-- Main Content -->
            <v-main>
                <v-container>
                    <div class="">
                        <slot />
                    </div>
                </v-container>
            </v-main>

            <!-- Footer -->
            <v-footer app>
                <v-col class="text-center text-xs">
                    © {{ new Date().getFullYear() }} The Teacher's Site
                </v-col>
            </v-footer>
        </v-app>
    </v-responsive>
</template>
