<script setup>
import ReportCardModal from '@/Components/Modals/ReportCardModal.vue';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useGoTo } from 'vuetify/lib/framework.mjs';
const toast = useToast();
const goTo = useGoTo();

const props = defineProps({
    curriculums: {
        type: Array,
        required: true,
    },
    subjects: {
        type: Array,
        required: true,
    },
    indicatorFormats: {
        type: Array,
        required: true,
    },
    pronouns: {
        type: Array,
        required: true,
    },
    config: {
        type: Object,
        required: false,
    }
});

const form = ref({
    name: null,
    curriculum_id: null,
    subject_id: null,
    stems: [],
    names: '',
    pronoun: null,
    save: true,
    indicator_format: null,
    student_name: null,
    positives: [],
    improvements: [],
    optional: false,
});

/**************************************************************
 *      Breadcrumbs
 **************************************************************/
    const breadcrumbs = computed(() => {
    return [
        {
            title: 'Dashboard',
            disabled: false,
            href: '/dashboard',
        },
        {
            title: 'Report Cards',
            disabled: false,
            href: '/report-cards',
        },
    ];
});

/**************************************************************
 *      State
 **************************************************************/

const positiveKeywords = ref([
    "Consistent Effort",
    "Resilient Learner",
    "Collaborative Team Player",
    "Creative Problem Solver",
    "Independent Thinker",
    "Strong Communicator",
    "Detail-Oriented and Organized",
    "Curious and Engaged"
]);

const improvementKeywords = ref([
    "Time Management",
    "Following Instructions",
    "Participation",
    "Organization Skills",
    "Persistence with Challenges",
    "Attention to Detail",
    "Collaboration Skills",
    "Independence",
]);

const step = ref(1);

const indicatorFormatsStepDropdown = computed(() => {
    if (!form.value.indicator_format) {
        return [];
    }
    return form.value.indicator_format.indicator_levels;
});
const stemPanels = ref([]);

const addNewStem = async (scroll = true) => {
    form.value.stems.push({
        stem: '',
        outcome: '',
        indicator: null,
        area: 'strength',
        override: '',
        is_selected: false,
    });

    stemPanels.value.push(form.value.stems.length - 1);

    await nextTick();

    if (scroll) {
        goTo(document.body.scrollHeight, { duration: 500, easing: 'easeInOutCubic' });
    }
};

onMounted(() => {
    if (props.config) {
        form.value = {
            name: props.config.name,
            save: true,
            curriculum_id: props.curriculums.find(x => x.id === props.config.curriculum_id),
            indicator_format: props.indicatorFormats.find(x => x.id === props.config.indicator_format_id),
            subject_id: props.subjects.find(x => x.id === props.config.subject_id),
            stems: props.config.stems.map(x => ({
                area: 'strength',
                stem: x.stem,
                outcome: x.outcome,
                indicator: null,
                override: '',
            })),
            uuid: props.config.uuid,
        }

        stemPanels.value = form.value.stems.map((_, index) => index);
    } else {
        addNewStem(false);
    }
});

const modal = ref(false);
const loading = ref(false);
const validationErrors = ref({});
const generatedComment = ref(null);

const lowercaseFirstLetter = (val) => {
    return String(val).charAt(0).toLowerCase() + String(val).slice(1);
}

const payload = ref({});

const generate = async () => {
    loading.value = true;

    const positiveIndices = form.value?.positives ?? [];
    const improvementIndices = form.value?.improvements ?? [];

    const improvementWords = improvementIndices.map(index => lowercaseFirstLetter(improvementKeywords.value[index]));
    const positiveWords = positiveIndices.map(index => lowercaseFirstLetter(positiveKeywords.value[index]));

    payload.value = {
        ...form.value,
        stems: [...form.value.stems.map(stem => ({
            ...stem,
            stem: lowercaseFirstLetter(stem.stem),
            outcome: lowercaseFirstLetter(stem.outcome),
        }))],
        positives: improvementWords,
        improvements: positiveWords,
    }

    validationErrors.value = {};

    axios.post('/api/v1/report-cards/generate-comment', payload.value)
        .then(response => {
            generatedComment.value = {
                strengths: response.data?.strength_responses,
                growths: response.data?.growth_responses,
                additional: response.data?.additional_narrative_response,
            };

            modal.value = true;
        })
        .catch(error => {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errors
            ) {
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                    toast.open({
                        message: 'There are missing fields. Please try again.',
                        type: 'error',
                    });
                }
            }
        }).finally(_ => {
            loading.value = false;
        });
};



const clearIndicators = () => {
    form.value.stems.forEach(stem => {
        stem.indicator = null;
    });
};

const clearName = () => {
    if (form.value.save) {
        form.value.name = null;
    }
};

const saveConfig = (e) => {
    if (e > 1) {
        if (form.value.save) {
            step.value = 1;
            validationErrors.value = {};
            axios.post('/api/v1/report-cards/config', {
                    ...form.value,
                })
                .then(response => {
                    form.value.id = response.data.config.id;
                    form.value.uuid = response.data.config.uuid;
                    step.value = 2;

                    toast.open({
                        message: 'Config saved!',
                        type: 'success',
                    });
                })
                .catch(error => {
                    step.value = 1;
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.errors
                    ) {
                        if (error?.response?.data?.errors) {
                            validationErrors.value = error.response.data.errors;

                            toast.open({
                                message: 'There are missing fields. Please try again.',
                                type: 'error',
                            });
                        }
                    }
                });
        }
    }
};

const goToNextStudent = () => {
    form.value = {
        ...form.value,
        student_name: '',
        pronoun: null,
        comments_growth: 1,
        comments_strength: 2,
        positives: [],
        improvements: [],
    }
    form.value.stems.forEach(stem => {
        stem.indicator = null;
        stem.is_selected = false;
    });
};

const mdSize = ref(false);
onMounted(() => {
    mdSize.value = window.innerWidth < 767;
});
</script>
<template>
    <AuthenticatedLayout>
        <ReportCardModal v-if="modal" :payload="payload" :isOpen="modal" @close="modal = false" :hide-save="true" cancel-text="Close" :comments="generatedComment" />
        <div class="pb-4 lg:py-8">
            <v-breadcrumbs class="-ml-3 mb-2 lg:mb-4" :items="breadcrumbs">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
            <div class="text-3xl self-center font-bold">Report Cards</div>
            <p class="text-sm text-gray-500">
                Generate report cards for your students.
            </p>
        </div>
        <div class="grid grid-cols-1">
            <v-stepper :mobile="mdSize" non-linear :hide-actions="step === 2" v-model="step" editable :items="['Configure Class Profile', 'Individual Student Report']" @update:modelValue="saveConfig($event)">
                <template v-slot:item.1>
                    <v-card title="" flat>
                        <div class="lg:ml-4 md:text-lg">Configure Your Class Profile:</div>
                        <div class="flex flex-col gap-2 mt-6">
                            <div class="flex flex-col gap-x-4 lg:flex-row items-center">
                                <v-text-field
                                    v-model="form.name"
                                    :disabled="!form.save"
                                    compact
                                    tabindex="1"
                                    placeholder="Ex. 2024 Term 1: ELA Grade 8-1"
                                    label="Create Class Name"
                                    class="w-full flex-1"
                                    :error="validationErrors.name ? true : false"
                                    :error-messages="validationErrors.name"
                                    hide-details
                                ></v-text-field>
                                <v-checkbox
                                    v-if="!form.uuid"
                                    v-model="form.save"
                                    compact
                                    :disabled="!!form.id"
                                    @click="clearName"
                                    class="lg:w-96"
                                    label="Save Config For Next Time?"
                                    hide-details
                                ></v-checkbox>
                            </div>
                            <div class="flex flex-col lg:grid lg:grid-cols-2 gap-4 mt-2">
                                <v-autocomplete
                                    v-model="form.curriculum_id"
                                    :items="curriculums"
                                    item-value="id"
                                    return-object
                                    item-title="name"
                                    :error="validationErrors.curriculum_id ? true : false"
                                    :error-messages="validationErrors.curriculum_id"
                                    tabindex="2"
                                    placeholder="Select National/State/Provincial Curriculum"
                                    label="Select National/State/Provincial Curriculum"
                                    compact
                                    clearable
                                    hide-details
                                />
                                <v-combobox
                                    v-model="form.subject_id"
                                    :items="subjects"
                                    tabindex="3"
                                    item-value="id"
                                    item-title="name"
                                    return-object
                                    :error="validationErrors.subject_id ? true : false"
                                    :error-messages="validationErrors.subject_id"
                                    placeholder="Select Subject"
                                    label="Select Subject"
                                    name="subject_id"
                                    compact
                                    clearable
                                    hide-details
                                />
                                <v-select
                                    v-model="form.indicator_format"
                                    :items="indicatorFormats"
                                    item-value="id"
                                    return-object
                                    @click="clearIndicators"
                                    :error="validationErrors.indicator_format ? true : false"
                                    class="col-span-2"
                                    item-title="name"
                                    placeholder="Select Indicator Format"
                                    label="Select Indicator Format"
                                    name="indicator_format_id"
                                    compact
                                    clearable
                                />
                            </div>
                            <div class="flex flex-col md:flex-row justify-between mb-3">
                                <div class="md:text-lg text-center lg:text-left mb-6 lg:mb-0 lg:ml-4">Add your Report Card Stems/Curricular Outcomes to be assessed:</div>
                                <v-btn
                                    @click="addNewStem"
                                    color="success"
                                    class="px-4 lg:px-0 lg:mr-4 mt-1"
                                    :class="form.stems.length === 1 && form.stems[0].stem && form.stems[0].outcome ? 'animate__animated animate__bounce' : ''"
                                    dark
                                >Add <v-icon icon="mdi-plus" v-tooltip="'Add'"></v-icon></v-btn>
                            </div>
                            <v-expansion-panels v-model="stemPanels" multiple>
                                <v-expansion-panel
                                    v-for="(stem, index) in form.stems"
                                    :key="index"
                                    text=""
                                    :id="'stem-panels-' + index"
                                >
                                <v-expansion-panel-title disable-icon-rotate>
                                    <div class="truncate">{{ stem.stem ? stem.stem : 'Report Card Stems/Curricular Outcomes ' + (index + 1) }}</div>
                                    <template v-slot:actions="{ expanded }">
                                        <div class="flex items-center">
                                        <v-icon :icon="!expanded ? 'mdi-chevron-down' : 'mdi-chevron-up'"></v-icon>
                                        <v-hover v-slot="{ isHovering }">
                                            <v-btn
                                                @click.stop="form.stems.splice(index, 1); stemPanels.splice(index, 1)"
                                                plain
                                                fab
                                                color="error"
                                                class="ml-4"
                                                :disabled="form.stems.length === 1 && index === 0"
                                                text
                                            >
                                            <v-icon
                                                class="cursor-pointer"
                                                icon="mdi-delete"
                                            ></v-icon>
                                        </v-btn>
                                        </v-hover>
                                    </div>
                                    </template>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                <div class="flex flex-col gap-2">
                                    <div class="hidden lg:block w-full">
                                        <v-text-field
                                            v-model="form.stems[index].stem"
                                            dense
                                            :error="validationErrors[`stems.${index}.stem`] ? true : false"
                                            :error-messages="validationErrors[`stems.${index}.stem`]"
                                            :label="'Report Card Stems/Curricular Outcomes ' + (index + 1)"
                                            hide-details
                                        />
                                    </div>
                                    <div class="lg:hidden w-full">
                                        <v-textarea
                                            v-model="form.stems[index].stem"
                                            dense
                                            :error="validationErrors[`stems.${index}.stem`] ? true : false"
                                            :error-messages="validationErrors[`stems.${index}.stem`]"
                                            :label="'Report Card Stems/Curricular Outcomes ' + (index + 1)"
                                            hide-details
                                        />
                                    </div>
                                    <v-textarea
                                            v-model="form.stems[index].outcome"
                                            dense
                                            :error="validationErrors[`stems.${index}.outcome`] ? true : false"
                                            :error-messages="validationErrors[`stems.${index}.outcome`]"
                                            class="mt-2"
                                            label="Diagnostic/Formative/Summative/Learning Task evaluations"
                                            hide-details
                                        />
                                </div>
                                </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </v-card>
                </template>

                <template v-slot:item.2>
                    <v-card title="" flat hide-controls>
                        <div class="md:text-lg lg:text-left mb-2 lg:ml-4">Individual Student Report:</div>
                        <div class="flex flex-col md:grid grid-cols-2 gap-4 mt-3">
                            <div class="flex flex-col md:flex-row md:gap-4 w-full mt-2 col-span-2">
                                <v-text-field
                                    :error="validationErrors.student_name ? true : false"
                                    :error-messages="validationErrors.student_name"
                                    v-model="form.student_name"
                                    name="name"
                                    label="Enter Student First Name"
                                    placeholder="John"
                                />
                                <div class="md:w-64">
                                    <v-autocomplete
                                        :error="validationErrors.pronoun ? true : false"
                                        :error-messages="validationErrors.pronoun"
                                        v-model="form.pronoun"
                                        :items="pronouns"
                                        name="pronoun"
                                        item-title="name"
                                        item-value="id"
                                        label="Pronoun"
                                        placeholder="Select"
                                    />
                                </div>

                                <v-btn
                                    :disabled="!generatedComment"
                                    @click="goToNextStudent"
                                    color="success"
                                    dark
                                >Next Student</v-btn>
                            </div>
                            <div class="col-span-2">
                                <p class="font-semibold col-span-2 text-center">Select Comments To Be Assessed for areas of <strong>Strength</strong> or <strong>Growth</strong></p>
                                <p class="mt-4 bg-gray-100/80 p-4 rounded text-sm"><strong>Instructions:</strong> For each <strong>Report Card Stem/Curricular Outcome</strong> you wish to generate a comment on, simply toggle the <strong>ST | GR BUTTON (ST = Strength & GR = Growth)</strong> and select the assessed <strong>INDICATOR</strong> from the dropdown menu on the right. These will be the only Report Card Stem/Curricular Outcomes that will generate a comment.</p>
                            </div>
                            <div class="col-span-2">
                                <div v-for="(stem, index) in form.stems" :key="stem" class="flex flex-col gap-4 items-center border-b last:border-b-0 border-gray-200 py-2">
                                    <div class="flex flex-col lg:flex-row w-full justify-between">
                                        <div class="flex items-center gap-4">
                                            <v-checkbox @click="stem.indicator = null" hide-details v-model="stem.is_selected"/>
                                                <div class="truncate" @click="stem.is_selected = !stem.selected">
                                                    {{ stem.stem }}
                                                    <v-tooltip
                                                        activator="parent"
                                                        location="top"
                                                    >{{stem.stem}}</v-tooltip>
                                                </div>
                                        </div>
                                        <div class="flex flex-col md:flex-row items-center gap-4">
                                            <v-btn-toggle
                                                v-model="stem.area"
                                                variant="outlined"
                                                density="comfortable"
                                                class="md:w-40"
                                                :disabled="!stem.is_selected"
                                                :color="stem.area === 'strength' ? 'primary' : 'red'"
                                                mandatory>
                                                <v-btn v-tooltip="'Strength'" value="strength"><div class="flex md:hidden">Strength</div><div class="hidden md:flex">ST</div></v-btn>
                                                <v-btn v-tooltip="'Growth'" value="growth"><div class="flex md:hidden">Growth</div><div class="hidden md:flex">GR</div></v-btn>
                                            </v-btn-toggle>
                                            <v-autocomplete
                                                :error="validationErrors[`stems.${index}.indicator`] ? true : false"
                                                :error-messages="validationErrors[`stems.${index}.indicator`]"
                                                hide-details
                                                :disabled="!stem.is_selected"
                                                class="w-full md:w-64"
                                                v-model="stem.indicator"
                                                return-object
                                                :items="indicatorFormatsStepDropdown"
                                                name="indicator"
                                                item-title="name"
                                                item-value="id"
                                                label="Indicator"
                                                placeholder="Select"
                                            />
                                        </div>
                                    </div>
                                    <div class="lg:pl-14 w-full col-span-2">
                                        <v-textarea placeholder="Optional field" rows="2" v-if="stem.is_selected" :disabled="!stem.is_selected" class="w-full truncate" v-model="stem.override">
                                            <template #details>
                                                <p style="white-space: normal; word-wrap: break-word;" class="text-xs italic">Optional field: Add Student Specific (adapted, modified or enrichment) tasks that will override the Class Profile Formative/Summative/Learning Task evaluations related to Report Card Stems/Curricular Outcomes</p>
                                            </template>
                                        </v-textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-gray-100/80 p-4 col-span-2 rounded">
                                <v-switch
                                    v-model="form.optional"
                                    label="Optional Narrative Comment"
                                    hide-details
                                    inset
                                    color="primary"
                                ></v-switch>
                                <p class="italic text-xs sm:text-sm">Additional 40-50 word paragraph personalizing the information above, yet not redundant</p>
                            </div>

                            <p class="font-semibold col-span-2 text-center">Add keyword describers for narrative (optional):</p>

                            <div class="bg-primary/5 p-4 rounded">
                                <div class="text-lg text-primary text-center">Strengths</div>
                                <v-chip-group multiple v-model="form.positives" column class="justify-center-v-chip">
                                    <v-chip v-for='positive in positiveKeywords' :key="positive" class="mr-2" color="primary" variant="outlined" label>
                                        {{ positive }}
                                    </v-chip>
                                </v-chip-group>
                            </div>

                            <div class="bg-red-300/20 p-4 rounded">
                                <div class="text-lg text-center text-red-500">Growths</div>
                                <v-chip-group multiple v-model="form.improvements" column class="justify-center-v-chip">
                                    <v-chip v-for='improvement in improvementKeywords' :key="improvement" class="mr-2" color="red" variant="outlined" label>{{ improvement }}</v-chip>
                                </v-chip-group>
                            </div>
                        </div>
                        <div class="flex justify-center my-12">
                            <v-btn size="large" color="primary" @click="generate" :disabled="loading">Generate <v-icon v-if="!loading" icon="mdi-auto-fix" class="ml-2"></v-icon><v-progress-circular v-else indeterminate class="ml-2" model-value="20"></v-progress-circular></v-btn>
                        </div>
                    </v-card>
                </template>
            </v-stepper>
        </div>
    </AuthenticatedLayout>
</template>
